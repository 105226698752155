<template>
  <div>
    <h2>Specific Heat of a Metal: Data + Calculations</h2>
    <v-form>
      <!-- Data Table -->
      <h3 class="pt-6 pb-4">Data Table</h3>
      <v-textarea
        v-model="inputs.data_table_caption"
        label="Table Caption"
        outlined
        placeholder="Write a caption for the data table here."
      />
      <v-simple-table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Units</th>
            <th>Trial 1</th>
            <th>Trial 2</th>
          </tr>
        </thead>
        <tr>
          <th>Identity</th>
          <td>&nbsp;</td>
          <td v-for="i in 2" :key="`known-${i}`">
            <v-select
              v-model="inputs[`trial${i}_identity`]"
              :items="['Zn', 'Cu']"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item" />
              </template>
            </v-select>
          </td>
        </tr>
        <tr v-for="{label, key, units: rowUnits} in dataTableRows" :key="key">
          <th><stemble-latex :content="label" /></th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <td v-for="i in 2" :key="`known-${key}-${i}`">
            <calculation-input v-model="inputs[`trial${i}_${key}`]" />
          </td>
        </tr>
      </v-simple-table>

      <!-- Calculations -->
      <h3 class="pt-8 pb-3">Calculations</h3>
      <v-textarea
        v-model="inputs.calculations_table_caption"
        label="Table Caption"
        outlined
        placeholder="Write a caption for the calculations table here."
      />
      <v-simple-table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Units</th>
            <th>Trial 1</th>
            <th>Trial 2</th>
          </tr>
        </thead>
        <tr
          v-for="{label, key, units: rowUnits, type: inputType} in calculationTableRows"
          :key="key"
        >
          <th><stemble-latex :content="label" /></th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <td v-for="i in 2" :key="`known-${key}-${i}`">
            <v-textarea
              v-if="inputType === 'string'"
              v-model="inputs[`trial${i}_${key}`]"
              outlined
              solo
              rows="3"
            />
            <calculation-input v-else v-model="inputs[`trial${i}_${key}`]" />
          </td>
        </tr>
      </v-simple-table>

      <h3 class="pt-6 pb-4">Sample Calculation</h3>
      <p>
        Upload a sample calculation for either trial for the calculation of the specific heat
        capacity and the % error.
      </p>
      <v-file-input v-model="sampleCalculationUploads" multiple />

      <h3 class="pt-6 pb-4">Graph</h3>
      <p>
        In this experiment, you are collecting temperature data as a function of time. Plot your
        temperature-time data (to complete your analysis you must plot the data for both trials, but
        we will only ask you to upload the graph for one of the two trials). Ensure that you place
        the correct data on the correct axis. Label your axes and indicate units where appropriate.
        Refer to Figure 2.1 in the Graphs section in the Experimental protocol and draw the vertical
        line at time of mixing. Then extrapolate back to the time of mixing from your stabilized
        final temperature (it can be horizontal or inclined depending on your data).
      </p>
      <v-file-input v-model="graphUploads" multiple />
      <v-textarea
        v-model="inputs.graphCaption"
        outlined
        label="Figure caption"
        placeholder="Enter a caption for the uploaded graph."
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OttawaLab1ReportTask2Data',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        data_table_caption: null,
        calculations_table_caption: null,
        graphCaption: null,

        mass_metal_units: null,
        initial_temp_metal_units: null,
        mass_calorimeter_units: null,
        volume_water_units: null,
        mass_calorimeter_and_water_units: null,
        initial_temp_water_units: null,
        final_temp_mixture_units: null,
        mass_water_units: null,
        delta_temp_water_units: null,
        specific_heat_water_units: null,
        delta_temp_metal_units: null,
        specific_heat_metal_units: null,
        literature_specific_heat_metal_units: null,
        percent_error_specific_heat_metal_units: null,

        trial1_identity: null,
        trial2_identity: null,
        trial1_mass_metal: null,
        trial2_mass_metal: null,
        trial1_initial_temp_metal: null,
        trial2_initial_temp_metal: null,
        trial1_mass_calorimeter: null,
        trial2_mass_calorimeter: null,
        trial1_volume_water: null,
        trial2_volume_water: null,
        trial1_mass_calorimeter_and_water: null,
        trial2_mass_calorimeter_and_water: null,
        trial1_initial_temp_water: null,
        trial2_initial_temp_water: null,
        trial1_final_temp_mixture: null,
        trial2_final_temp_mixture: null,
        trial1_mass_water: null,
        trial2_mass_water: null,
        trial1_delta_temp_water: null,
        trial2_delta_temp_water: null,
        trial1_specific_heat_water: null,
        trial2_specific_heat_water: null,
        trial1_reference_specific_heat_water: null,
        trial2_reference_specific_heat_water: null,
        trial1_delta_temp_metal: null,
        trial2_delta_temp_metal: null,
        trial1_specific_heat_metal: null,
        trial2_specific_heat_metal: null,
        trial1_literature_specific_heat_metal: null,
        trial2_literature_specific_heat_metal: null,
        trial1_reference_specific_heat_metal: null,
        trial2_reference_specific_heat_metal: null,
        trial1_percent_error_specific_heat_metal: null,
        trial2_percent_error_specific_heat_metal: null,
      },
      graphUploads: [],
      sampleCalculationUploads: [],
      units: [
        {key: 'g', label: '$\\text{g}$'},
        {key: 'C', label: '$^{\\omicron}\\text{C}$'},
        {key: 'mL', label: '$\\text{mL}$'},
        {
          key: 'JGC',
          label: '$\\text{J} \\cdot \\text{g}^{-1} \\cdot {^{\\omicron}\\text{C}}^{-1}$',
        },
        {
          key: 'percent',
          label: '$\\%$',
        },
      ],
      dataTableRows: [
        {label: 'Mass of Metal', key: 'mass_metal', units: true},
        {label: 'Initial Temperature of Metal', key: 'initial_temp_metal', units: true},
        {label: 'Mass of Calorimeter', key: 'mass_calorimeter', units: true},
        {label: 'Volume of Water', key: 'volume_water', units: true},
        {label: 'Mass (Water + Calorimeter)', key: 'mass_calorimeter_and_water', units: true},
        {label: 'Initial Temperature of Water', key: 'initial_temp_water', units: true},
        {label: 'Temperature of Mixture', key: 'final_temp_mixture', units: true},
      ],
      calculationTableRows: [
        {label: 'Mass of Water', key: 'mass_water', units: true},
        {label: '$\\Delta T_{water}$', key: 'delta_temp_water', units: true},
        {label: '$c_{water}$', key: 'specific_heat_water', units: true},
        {
          label: 'Reference for $c_{water}$',
          key: 'reference_specific_heat_water',
          units: false,
          type: 'string',
        },
        {label: '$\\Delta T_{metal}$', key: 'delta_temp_metal', units: true},
        {label: '$c_{metal}$ (Experimental)', key: 'specific_heat_metal', units: true},
        {label: '$c_{metal}$ (Literature)', key: 'literature_specific_heat_metal', units: true},
        {
          label: 'Reference for $c_{metal}$',
          key: 'reference_specific_heat_metal',
          units: false,
          type: 'string',
        },
        {label: 'Percent Error', key: 'percent_error_specific_heat_metal', units: true},
      ],
    };
  },
  computed: {
    attachments() {
      return [...this.graphUploads, ...this.sampleCalculationUploads];
    },
  },
};
</script>
